import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {MatCardModule} from '@angular/material/card';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatTableModule} from '@angular/material/table';
import {MatListModule} from '@angular/material/list';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatStepperModule} from '@angular/material/stepper';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {FlexLayoutModule} from '@angular/flex-layout';

import {MatSortModule} from '@angular/material/sort';
import {MatPaginatorModule} from '@angular/material/paginator';

import {MatNativeDateModule} from '@angular/material/core';
import {TimeAgoPipe} from 'time-ago-pipe';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatBadgeModule} from '@angular/material/badge';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSliderModule} from '@angular/material/slider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatChipsModule} from '@angular/material/chips';

import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment.prod';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {LoginStatusComponent} from './login-status/login-status.component';
import {LoginComponent} from './login/login.component';
import {SnackBarComponent} from './snackbar/snackbar.component';
import {HttpClientModule} from '@angular/common/http';
import { ProjectsComponent } from './projects/projects.component';
import {NewProjectDialogComponent} from './projects/new-project-dialog/new-project-dialog';
import { ProjectComponent } from './projects/project/project.component';
import { TaskComponent } from './projects/project/task/task.component';
import {ClickOutsideDirective} from './click-outside.directive';
import {EditProjectDialogComponent} from './projects/edit-project-dialog/edit-project-dialog';

import { MentionModule } from 'angular-mentions';
import {GroupsComponent} from './groups/groups.component';
import {LinkProviderDialogComponent} from './projects/link-provider-dialog/link-provider-dialog';
import {MatSidenavModule} from '@angular/material/sidenav';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { HelpComponent } from './help/help.component';
import {NgStringPipesModule} from 'ngx-pipes';
import {ConversationComponent} from './projects/project/conversation/conversation.component';
import {HttpModule} from '@angular/http';
import {AnnouncementsComponent} from './announcements/announcements.component';
import {NewAnnouncementDialogComponent} from './announcements/new-announcement-dialog/new-announcement-dialog';
import {NewGoogleUserComponent} from './projects/project/new-google-user/new-google-user';
import {ViewGoogleUserComponent} from './projects/project/view-google-user/view-google-user';
import {EmployeeTaskComponent} from './kanban/employee-task/employee-task.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {KanbanComponent} from './kanban/kanban.component';
import {NewKanbanTaskDialogComponent} from './kanban/new-kanban-task-dialog/new-kanban-task-dialog';
import {ExportDataDialogComponent} from './projects/export-data-dialog/export-data-dialog';
import {ReferralsComponent} from './referrals/referrals.component';
import {ReferralDialogComponent} from './referrals/referral-dialog/referral-dialog';
import {HomeComponent} from './home/home.component';
import {TextMaskModule} from 'angular2-text-mask';
import {QuickTaskDialogComponent} from './projects/project/quick-task-dialog/quick-task-dialog';
import {SettingsComponent} from './settings/settings.component';

import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';

import {
    AngularFirestoreAmd,
    AngularFireAuthAmd,
} from './firebase.factory';

import { PLATFORM_ID, NgZone, LOCALE_ID } from '@angular/core';
import {CaseloadsComponent} from './caseloads/caseloads.component';
import {ProviderDialogComponent} from './caseloads/provider-dialog/provider-dialog';
import {ProviderAvailabilityComponent} from './caseloads/provider-availability/provider-availability';
import {ConfirmDialogComponent} from './referrals/confirm-dialog/confirm-dialog';
import {UnsavedChangesDialogComponent} from './referrals/unsaved-changes-dialog/unsaved-changes-dialog';
import {AssignDialogComponent} from './referrals/assign-dialog/assign-dialog';
import {HistoryItemComponent} from './referrals/referral-dialog/history-item/history-item.component';
import {StageFilterButtonComponent} from './referrals/stage-filter-button/stage-filter-button.component';
import {CreateTaskComponent} from './referrals/create-task/create-task.component';
import {TaskItemComponent} from './referrals/referral-dialog/task-item/task-item.component';
import {NextActivityComponent} from './referrals/referral-dialog/next-activity/next-activity.component';
import {StatusesBadgesComponent} from './referrals/statuses-badges/statuses-badges';
import {TasksComponent} from './referrals/tasks/tasks.component';


const firebaseUiAuthConfig: firebaseui.auth.Config = {
    signInFlow: 'redirect',
    signInOptions: [
        'microsoft.com',
        // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        // firebase.auth.EmailAuthProvider.PROVIDER_ID,
        // {
        //   provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        //   signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
        // },
        // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
        // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
    ],
    // tosUrl: '<your-tos-link>',
    // privacyPolicyUrl: '<your-privacyPolicyUrl-link>',
    credentialHelper: firebaseui.auth.CredentialHelper.NONE
};

@NgModule({
    declarations: [
        AppComponent,
        LoginStatusComponent,
        LoginComponent,
        SnackBarComponent,
        ProjectsComponent,
        NewProjectDialogComponent,
        EditProjectDialogComponent,
        ProjectComponent,
        TaskComponent,
        ClickOutsideDirective,
        TimeAgoPipe,
        GroupsComponent,
        LinkProviderDialogComponent,
        HelpComponent,
        ConversationComponent,
        AnnouncementsComponent,
        NewAnnouncementDialogComponent,
        NewGoogleUserComponent,
        ViewGoogleUserComponent,
        KanbanComponent,
        EmployeeTaskComponent,
        NewKanbanTaskDialogComponent,
        ExportDataDialogComponent,
        ConfirmDialogComponent,
        UnsavedChangesDialogComponent,
        ReferralsComponent,
        ReferralDialogComponent,
        HomeComponent,
        QuickTaskDialogComponent,
        SettingsComponent,
        CaseloadsComponent,
        ProviderDialogComponent,
        ProviderAvailabilityComponent,
        AssignDialogComponent,
        HistoryItemComponent,
        StageFilterButtonComponent,
        CreateTaskComponent,
        TaskItemComponent,
        NextActivityComponent,
        StatusesBadgesComponent,
        TasksComponent,
    ],
    imports: [
        BrowserModule,
        HttpModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatCardModule,
        MatToolbarModule,
        MatButtonModule,
        MatExpansionModule,
        MatInputModule,
        MatMenuModule,
        MatIconModule,
        MatTableModule,
        MatNativeDateModule,
        MatDatepickerModule,
        MatSidenavModule,
        MatProgressSpinnerModule,
        MatPaginatorModule,
        MatListModule,
        MatStepperModule,
        FormsModule,
        ReactiveFormsModule,
        MatTabsModule,
        MatSelectModule,
        MatSortModule,
        MatButtonToggleModule,
        MatTooltipModule,
        MatDialogModule,
        MatGridListModule,
        MatCheckboxModule,
        MatRadioModule,
        MatDividerModule,
        MatSnackBarModule,
        MatProgressBarModule,
        MatBadgeModule,
        MatSlideToggleModule,
        MatAutocompleteModule,
        MatSliderModule,
        FlexLayoutModule,
        MatChipsModule,
        DragDropModule,
        TextMaskModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        MentionModule,
        NgStringPipesModule,
        FirebaseUIModule.forRoot(firebaseUiAuthConfig),
        ScrollToModule.forRoot()
    ],
    providers: [{
        provide: 'env',
        useValue: environment
    },
        {
            provide: 'firebaseAmd',
            deps: [PLATFORM_ID, NgZone],
            useFactory: AngularFirestoreAmd
        },
        {
            provide: 'firebaseAuthAmd',
            deps: [PLATFORM_ID, NgZone],
            useFactory: AngularFireAuthAmd
        }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
