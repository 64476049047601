import {Component, Input, OnChanges, OnInit} from '@angular/core';
import * as moment from 'moment/moment';
import {ReferralsService} from '../../../services/referrals.service';

@Component({
    selector: 'app-history-item',
    templateUrl: './history-item.component.html',
    styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent implements OnInit, OnChanges {

    @Input() history;
    @Input() theme = 'light';
    @Input() display_inline = false;
    @Input() referral_type;

    history_summary = '';
    history_details = '';
    initialized = false;

    constructor(
        private referralsService: ReferralsService) { }

    ngOnInit() {
        this.generateHistorySummary();
        this.initialized = true;
    }

    ngOnChanges() {
        if (!this.initialized) {
            return;
        }

        this.generateHistorySummary();
    }

    generateHistorySummary() {
        try {
            const action = this.history.action;
            if (!action.name) {
                // TODO this case is for the assigned_to action, maybe we just make this a normal action with a details field?
                this.history_summary = this.history.action.pretty_printed ?? 'Unknown action';
                return;
            }

            let schema;
            if (action.name === 'actions') {
                schema = this.referralsService.lead_definitions[this.referral_type]['actions']['options'].find(option => option.value === action.status);
                const action_label_past_tense = schema.label_past_tense;
                this.history_summary = `${action_label_past_tense ?? this.history.action.pretty_printed}`;
            } else {
                const statuses = this.referralsService.lead_definitions[this.referral_type]['statuses'][action.name].statuses;
                schema = statuses.find(option => option.value === action.status);
                const action_label = this.referralsService.lead_definitions[this.referral_type]['statuses'][action.name].label;
                this.history_summary = `${action_label} changed to <b>${schema.label}</b>`;
            }

            if (!schema) {
                this.history_summary = `Unknown action: ${this.history.action.pretty_printed}`;
            }

            // create the details string
            let details = '';
            if (schema.details && schema.details.length) {
                // details += 'Details:<br>';
                for (const schema_detail of schema.details) {
                    const detail_name = schema_detail.name;
                    const detail_label = schema_detail.label;
                    const detail_value = this.history.action.details[detail_name];
                    if (detail_value) {
                        // if the detail is a date, format it
                        let detail_value_formatted;
                        if (schema_detail.type === 'date') {
                            // check if detail_value is already a javascript Date object
                            // covers case when we're using local form group data prior to saving
                            // after saving, the date is a firestore Timestamp object
                            // before saving, the date is a Date object
                            if (detail_value.toDate) {
                                detail_value_formatted = this.getHistoryDate(detail_value.toDate());
                            } else {
                                detail_value_formatted = this.getHistoryDate(new Date(detail_value));
                            }
                        } else {
                            detail_value_formatted = detail_value;
                        }
                        details += `<li><b>${detail_label}:</b> ${detail_value_formatted}</li>`;
                    }
                }
            }
            this.history_details = details;
        } catch (e) {
            console.warn(e);
            this.history_summary = 'Error generating history summary';
        }
    }

    getHistoryDate(date) {
        return moment(date).format('L');
    }

    getHistoryTooltip(date, user) {
        return `Updated ${moment(date).format('llll')} by ${user}`;
    }
}
