import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';

import {environment} from '../../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';
import {SanityService} from '../../services/sanity.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {AuthService} from '../../auth/auth.service';

@Component({
    selector: 'app-provider-dialog',
    templateUrl: 'provider-dialog.html',
    styleUrls: ['provider-dialog.scss']
})
export class ProviderDialogComponent implements OnInit, OnDestroy {

    constructor(
        private http: HttpClient,
        private toastsService: ToastsService,
        public globalService: GlobalService,
        public projectsService: ProjectsService,
        public dialogRef: MatDialogRef<ProviderDialogComponent>,
        public sanityService: SanityService,
        public authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.provider = data?.provider || null;
        this.canEdit = data?.canEdit || false;
    }
    provider;
    canEdit = false;
    providerFormGroup: FormGroup;
    paycomProviders = [];
    filteredPaycomProviders: Observable<any[]>;

    protected readonly environment = environment;

    ngOnInit() {
        console.log(this.provider);
        this.loadPaycomProviders();

        this.providerFormGroup = this._formBuilder.group({
            _id: [this.provider?._id ?? null, Validators.required],
            numNewPatientsPerWeek: [this.provider?.numNewPatientsPerWeek ?? null],
            comments: [this.provider?.comments ?? ''],
            paycomProviderName: [''],
        });

        this.filteredPaycomProviders = this.providerFormGroup.get('paycomProviderName').valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }

    private _filter(value: string): string[] {
        const filterValue = value.toLowerCase();
        return this.paycomProviders.filter(provider => `${provider.FirstName.toLowerCase()}${provider.LastName.toLowerCase()}`.includes(filterValue));
    }

    selectPaycomProvider(paycomProviderId: string) {
        console.log(paycomProviderId, this.provider.columnId);
        this.http
            .post(`${environment.apiBaseUrl}paycom-users/link`, {
                password: 'Clarity9404',
                columnId: this.provider.columnId,
                paycomId: paycomProviderId,
            }).subscribe(result => {
            console.log(result);
            this.globalService.saving = false;
            this.toastsService.showSnackBar('Provider Paycom connection updated', 'success', 3000);
        }, error => {
            this.globalService.saving = false;
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    getProviderName() {
        const foundProvider = this.paycomProviders.find(provider => provider.PaycomId === this.provider.paycomId);
        return this.titleCase(foundProvider ? foundProvider?.LastName + ', ' + foundProvider?.FirstName : '');
    }

    titleCase(str) {
        return str.toLowerCase().split(' ').map(function(word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }

    loadPaycomProviders() {
        try {
            this.globalService.saving = true;
            const url = environment.apiBaseUrl + 'paycom-users';
            const params = new HttpParams()
                .set('password', 'Clarity9404');
            this.http.get(url, {observe : 'response'}).subscribe(result => {
                if (result.status === 200) {
                    console.log(result.body);
                    this.paycomProviders = result.body as [];
                    this.providerFormGroup.patchValue({
                        paycomProviderName: this.getProviderName(),
                    });
                    this.globalService.saving = false;
                }
            }, error => {
                console.log(error);
                this.globalService.saving = false;
                this.toastsService.showSnackBar(error['error']['error'], 'error', 5000);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.globalService.saving = false;
        }
    }

    save() {
        if (this.providerFormGroup.invalid) {
            this.findInvalidControls();
            return;
        }

        this.globalService.saving = true;

        const provider = this.providerFormGroup.getRawValue();
        console.log(provider);

        this.http
            .post(`${environment.apiBaseUrl}provider`, {
                password: 'Clarity9404',
                provider: provider,
                previous_provider: this.provider,
                updated_by: this.authService.user.email,
            }).subscribe(result => {
                console.log(result);
                this.globalService.saving = false;
                this.closeDialog(this.provider.columnId);
            }, error => {
                this.globalService.saving = false;
                this.toastsService.showSnackBar(error, 'error', 5000);
            });
    }

    findInvalidControls() {
        const invalid = [];
        const controls = this.providerFormGroup.controls;
        for (const name in controls) {
            if (controls[name].invalid) {
                invalid.push(name);
            }
        }
        console.log(invalid);
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

    ngOnDestroy() {
    }
}
