import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import * as moment from 'moment/moment';
import {ReferralsService} from '../../../services/referrals.service';

@Component({
    selector: 'app-task-item',
    templateUrl: './task-item.component.html',
    styleUrls: ['./task-item.component.scss']
})
export class TaskItemComponent implements OnInit, OnChanges {

    @Input() task;

    initialized = false;

    constructor(
        private referralsService: ReferralsService) { }

    ngOnInit() {
        this.initialized = true;
    }

    ngOnChanges() {
        if (!this.initialized) {
            return;
        }

    }


    getDate(date) {
        return moment(date).format('L');
    }

    getDateTime(date) {
        return moment(date).format('llll');
    }
}
