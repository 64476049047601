import {GlobalService} from '../services/global.service';
import {ToastsService} from '../services/toasts.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable} from '@angular/material/sort';
import {ProjectsService} from '../services/projects.service';
import {GroupsService} from '../services/groups.service';
import {Subscription, timer} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {environment} from '../../environments/environment.prod';
import {ProviderDialogComponent} from './provider-dialog/provider-dialog';
import {SanityService} from '../services/sanity.service';


@Component({
    selector: 'app-caseloads',
    templateUrl: './caseloads.component.html',
    styleUrls: ['./caseloads.component.scss']
})
export class CaseloadsComponent implements OnInit, AfterViewInit, OnDestroy {
    protected readonly environment = environment;

    openDialogRef;

    referrals = [];
    loading = true;
    membershipsLoaded = false;
    viewAssigned = false;
    selectedInsurance = null;
    selectedLocation = 'Online';
    selectedSpecialties = [];
    selectedLanguages = [];
    selectedPatientType = 'adults';
    selectedTreatmentType = null;
    selectedTaskName = null;
    selectedAssignedGroup = null;
    selectedAge = null;
    filtersLoading = true;
    showUnlinkedOnly = false;
    includeInactive = false;

    locations = Object.entries(environment.facilities);

    membershipsLoadedSubscription: Subscription;

    providersMap = {};
    providers = [];

    specialties = [];
    insurances = [];
    patientTypes = [];
    treatmentTypes = [];
    languages = [];
    timerSubscription: Subscription;
    initialLoad = true;

    providersDataSource = new MatTableDataSource(null);
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    providersColumns: string[] = [
        'provider',
        // 'treatmentTypes',
        'location',
        'patientTypes',
        'availability',
        'numNewPatientsPerWeek',
        'comments',
        // 'status',
    ];

    constructor(public globalService: GlobalService,
                private groupsService: GroupsService,
                public projectsService: ProjectsService,
                public dialog: MatDialog,
                private http: HttpClient,
                private toastsService: ToastsService,
                public sanityService: SanityService,
                public authService: AuthService) {
    }

    ngOnInit() {
        this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
            this.membershipsLoaded = loaded;
            if (this.membershipsLoaded) {
                this.handleRouteAuthentication();
            }
        });
        this.providersDataSource.filterPredicate = function(data, filter: string): boolean {
            if (data['firstName'] != null && data['lastName'] != null) {
                return data['firstName'].toLowerCase().includes(filter)
                    || data['lastName'].toLowerCase().includes(filter);
            } else {
                return false;
            }
        };
        this.loadFilters();
        this.timerSubscription = timer(0, 12000).subscribe(n => this.loadProviders());
    }

    canEdit() {
        const allowedUsers = [
            'dredwards@claritychi.com',
            'ledwards@claritychi.com',
        ];
        if (allowedUsers.includes(this.authService.user.email)) {
            return true;
        }

        let allowedViaGroup = false;
        const allowedGroups =         [
            'alltherapydirectors@claritychi.com',
            // 'practicemanagermaster@claritychi.com',
            'alltherapysupervisors@claritychi.com',
            'clinicalsupervisors@claritychi.com',
            'clinicalsupervisorsah@claritychi.com',
            'projectmanagementleadership@claritychi.com',
            'projectmanagementdirectors@claritychi.com',
        ];

        for (const group of this.groupsService.currentUsersGroups) {
            if (allowedGroups.includes(group)) {
                allowedViaGroup = true;
            }
        }
        return allowedViaGroup || this.authService.admin;
    }

    openProvider(provider) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.width = '1000px';

        dialogConfig.data = {
            provider,
            canEdit: this.canEdit() && !provider.treatmentTypes.includes('psychiatry'), // TODO temporary until we have a better way to determine if user can edit
        };

        this.openDialogRef = this.dialog.open(ProviderDialogComponent, dialogConfig);

        this.openDialogRef.afterClosed().subscribe(async columnId => {
            if (columnId) {
                this.loadProviders();
            }
        });
    }

    handleRouteAuthentication() {
        // all users allowed access to referrals page
        return;

        // const allowedUsers = [
        //     'teftekhar@claritychi.com',
        //     'slott@claritychi.com',
        //     'nkittiviriya@claritychi.com',
        // ];
        //
        // if (allowedUsers.includes(this.authService.user.email)) {
        //     return;
        // }

        // const allowedGroups = [
        //     'practicemanagermaster@claritychi.com',
        //     'practicemanagermaster@claritychi.com',
        // ];
        //
        // for (const group of this.groupsService.currentUsersGroups) {
        //     if (allowedGroups.includes(group)) {
        //         return;
        //     }
        // }

        // this.router.navigate(['help']);
    }

    ngAfterViewInit() {
        this.providersDataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'provider': return `${data['firstName']} ${data['lastName']}`.toLocaleLowerCase();
                case 'numNewPatientsPerWeek': return data['numNewPatientsPerWeek'];
                case 'availability': return (data['nextAvailability'] && data['nextAvailability']['availability']) ? data['nextAvailability']['availability'] : 0;
                default: return data[sortHeaderId].toLocaleLowerCase();
            }
        };
        this.providersDataSource.paginator = this.paginator;
    }

    loadProviders() {
        if (this.initialLoad) {
            this.loading = true;
            this.providersDataSource.data = [];
        }

        try {
            const url = environment.apiBaseUrl + 'providers';
            const params = new HttpParams()
                .set('password', 'Clarity9404');
            this.http.get(url, {observe : 'response'}).subscribe(result => {
                if (result.status === 200) {
                    this.providersMap = result.body;

                    const providers = [];
                    for (const columnId in this.providersMap) {
                        if (this.providersMap.hasOwnProperty(columnId)) {
                            const provider = this.providersMap[columnId];
                            providers.push(provider);
                        }
                    }

                    this.providers = providers;

                    if (this.initialLoad) {
                        this.sort.sort(({id: 'numNewPatientsPerWeek', start: 'desc'}) as MatSortable);
                    }
                    this.providersDataSource.sort = this.sort;
                    this.providersDataSource.data = providers;
                    this.updateView();
                    this.loading = false;
                    this.initialLoad = false;
                }
            }, error => {
                console.log(error);
                this.loading = false;
                this.toastsService.showSnackBar(error['error']['error'], 'error', 5000);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.loading = false;
        }
    }

    loadFilters() {
        this.filtersLoading = true;
        this.specialties = [];
        this.insurances = [];
        this.patientTypes = [];
        this.languages = [];
        this.treatmentTypes = [];

        try {
            const url = environment.apiBaseUrl + 'providers/filters';
            this.http.get(url, {observe : 'response'}).subscribe(result => {
                if (result.status === 200) {
                    this.specialties = result.body['specialties'];
                    this.insurances = result.body['insurances'];
                    this.patientTypes = result.body['patientTypes'];
                    this.treatmentTypes = result.body['treatmentTypes'];
                    this.languages = result.body['languages'];
                    this.filtersLoading = false;
                }
            }, error => {
                console.log(error);
                this.filtersLoading = false;
                this.toastsService.showSnackBar(error['error']['error'], 'error', 5000);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.filtersLoading = false;
        }
    }

    applyFilter(filterValue: string) {
        this.providersDataSource.filter = filterValue.trim().toLowerCase();
    }

    selectLocation(location: string) {
        this.selectedLocation = location;
        // this.selectedFacilityId = this.getFacilityIdFromLocation(location) || null;
        this.updateView();
    }

    selectSpecialty(specialty: any) {
        if (!specialty) {
            this.selectedSpecialties = [];
        }
        this.updateView();
    }

    selectLanguage(language: any) {
        if (!language) {
            this.selectedLanguages = [];
        }
        this.updateView();
    }

    selectInsurance(insurance) {
        if (!insurance) {
            this.selectedInsurance = null;
        }
        this.updateView();
    }

    selectTreatmentType(treatmentType: any) {
        if (!treatmentType) {
            this.selectedTreatmentType = null;
        }
        this.updateView();
    }

    selectPatientType(patientType: any) {
        if (!patientType) {
            this.selectedPatientType = null;
        }
        this.updateView();
    }

    onChangeShowUnlinkedOnly() {
        this.showUnlinkedOnly = !this.showUnlinkedOnly;
        this.updateView();
    }

    onChangeIncludeInactive() {
        this.includeInactive = !this.includeInactive;
        this.updateView();
    }

    updateView() {
        let filteredProviders = this.providers;

        if (this.showUnlinkedOnly) {
            filteredProviders = filteredProviders.filter(p => !p['paycomId']);
        }

        if (!this.includeInactive) {
            filteredProviders = filteredProviders.filter(p => p['allowBooking'] === true);
        }

        if (this.selectedLocation !== 'Online') {
            filteredProviders = filteredProviders.filter(p => p['facilityId'] === this.selectedLocation);
        }

        if (this.selectedSpecialties.length > 0) {
            filteredProviders = filteredProviders.filter(p => this.selectedSpecialties.every(i => p['specialties'].includes(i)));
        }

        if (this.selectedLanguages.length > 0) {
            filteredProviders = filteredProviders.filter(p => this.selectedLanguages.every(i => p['languages'].includes(i)));
        }

        if (this.selectedInsurance) {
            filteredProviders = filteredProviders.filter(p => p['insurances'].includes(this.selectedInsurance));
        }

        if (this.selectedPatientType) {
            filteredProviders = filteredProviders.filter(p => p['patientTypes'].includes(this.selectedPatientType));
        }

        if (this.selectedTreatmentType) {
            filteredProviders = filteredProviders.filter(p => p['treatmentTypes'].includes(this.selectedTreatmentType));
        }

        if (this.canDetermineAvailability()) {
            filteredProviders = this.enrichWithAvailability(filteredProviders);
            // TODO this was commented to allow seeing all providers so we can set paycom to amd links
            // filteredProviders = filteredProviders.filter(p => p['nextAvailability']);
        }

        this.providersDataSource.data = filteredProviders;
    }

    canDetermineAvailability() {
        return this.selectedPatientType;
    }

    enrichWithAvailability(filteredProviders) {
        const providersWithAvailability = [...filteredProviders];
        for (const provider of providersWithAvailability) {
            provider['nextAvailability'] = null;
            provider['nextSlots'] = null;
            const apptType = provider.allowedAppointmentTypes.find((appt_type) => {
                return appt_type.patientType === this.selectedPatientType
                    && (
                        (appt_type.treatmentType === 'therapy' && appt_type.patientType !== 'testing')
                        || (appt_type.treatmentType === 'psychiatry' && appt_type.patientType !== 'testing')
                        || (appt_type.treatmentType === 'unknown' && appt_type.patientType === 'testing')
                    )
                    && appt_type.patient === 'new'
                    && (
                        (appt_type.isTelemedicine && this.selectedLocation === 'Online')
                    || (!appt_type.isTelemedicine && this.selectedLocation !== 'Online'));
            });
            if (apptType && provider['availability'] && provider['availability'][apptType.id]) {
                provider['nextAvailability'] = provider['availability'][apptType.id];
                if (provider['earliest_slots'] && provider['earliest_slots'][apptType.id]) {
                    provider['nextSlots'] = provider['earliest_slots'][apptType.id];
                }
            }
        }

        return providersWithAvailability;
    }

    ngOnDestroy() {
        this.membershipsLoadedSubscription.unsubscribe();
        this.timerSubscription.unsubscribe();
    }
}
