import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {GroupsService} from '../../services/groups.service';
import {AuthService} from '../../auth/auth.service';
import {map, startWith} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-edit-project-dialog',
    templateUrl: 'edit-project-dialog.html',
    styleUrls: ['edit-project-dialog.scss']
})
export class EditProjectDialogComponent implements OnInit {

    project;
    projectFormGroup: FormGroup;
    filteredTitles: Observable<string[]>;
    selectedTemplate = 'Any';

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        public globalService: GlobalService,
        public projectsService: ProjectsService,
        private groupsService: GroupsService,
        public dialogRef: MatDialogRef<EditProjectDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        this.project = data.project || null;
    }

    ngOnInit() {
        console.log(this.project);
        this.selectedTemplate = this.project.template;
        if (this.project) {
            this.projectFormGroup = this._formBuilder.group({
                locationCtrl: [this.project.location, Validators.required],
                firstNameCtrl: [this.project.first_name, Validators.required],
                lastNameCtrl: [this.project.last_name, Validators.required],
                licenseCtrl: [this.project.license, Validators.required],
                department: [this.project.department],
                role: [this.project.role],
                titleCtrl: [this.project.title],
                phoneCtrl: [this.project.cell_phone],
                personalEmailCtrl: [this.project.personal_email],
                dueDateCtrl: [this.project.due_date.toDate(), Validators.required],
                archivedCtrl: [this.project.archived, Validators.required],
                archivedCommentsCtrl: this.project?.archived_comments ?? '',
            });
        } else {
            this.toastsService.showSnackBar('No project data found, please try again', 'error');
            this.onNoClick();
        }
    }

    saveProject() {
        try {
            this.globalService.saving = true;
            const location = this.projectFormGroup.get('locationCtrl').value;
            const due_date = this.projectFormGroup.get('dueDateCtrl').value;
            const first_name = this.projectFormGroup.get('firstNameCtrl').value;
            const last_name = this.projectFormGroup.get('lastNameCtrl').value;
            const license = this.projectFormGroup.get('licenseCtrl').value;
            const title = this.projectFormGroup.get('titleCtrl').value;
            const cell_phone = this.projectFormGroup.get('phoneCtrl').value;
            const personal_email = this.projectFormGroup.get('personalEmailCtrl').value;
            const archived = this.projectFormGroup.get('archivedCtrl').value;
            const archived_comments = this.projectFormGroup.get('archivedCommentsCtrl').value;
            const updated_by = this.authService.user.displayName;
            const updated = new Date();

            const updatedProject = {
                location: location,
                first_name: first_name,
                last_name: last_name,
                first_name_lowercase: first_name.toLowerCase(),
                last_name_lowercase: last_name.toLowerCase(),
                license: license,
                title: title,
                role: this.projectFormGroup.get('role').value,
                department: this.projectFormGroup.get('department').value,
                cell_phone: cell_phone,
                personal_email: personal_email,
                archived: archived,
                archived_comments: archived_comments,
                due_date: due_date,
                updated_by: updated_by,
                updated: updated
            };

            let tasks;
            if (updatedProject.due_date.getTime() !== this.project.due_date.toDate().getTime()) {
                tasks = this.updateTaskDueDates(this.project.tasks, due_date);
            }
            if (updatedProject.first_name !== this.project.first_name || updatedProject.last_name !== this.project.last_name) {
                tasks = this.updateTaskProviderNames(this.project.tasks, first_name, last_name);
            }

            this.saveProjectToFirestore(updatedProject, tasks);
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
            this.globalService.saving = false;
        }
    }

    saveProjectToFirestore(project, tasks) {
        // archive test projects
        if (project.first_name.toLowerCase() === 'test' || project.first_name.toLowerCase() === 'testing') {
            project.archived = true;
        }
        const batch = this.afs.firestore.batch();
        const id = this.project.id;
        const projectsRef = this.afs.firestore.doc('projects/' + id);
        batch.set(projectsRef, project, {merge: true});

        // if start date changed, alert all groups (unless TEST, TEST)
        if (project.first_name.toLowerCase() !== 'test' && project.first_name.toLowerCase() !== 'testing') {
            if (project.due_date.getTime() !== this.project.due_date.toDate().getTime()) {
                const newEmailRef = this.afs.firestore.collection('mail').doc();
                const groups = this.groupsService.getGroupsAssignedToProject(tasks);
                const call_to_action_url = 'https://dashboard.claritychi.com/projects/' + id;
                const project_name = project.first_name + ' ' + project.last_name;
                const email_data = {
                    to: groups,
                    from: 'Clarity Clinic <noreply@claritychi.com>',
                    replyTo: 'Clarity Clinic <noreply@claritychi.com>',
                    template: {
                        name: 'start-date-notification',
                        data: {
                            subject: 'Start date changed for ' + project_name,
                            project_name: project_name,
                            old_start_date: this.project.due_date.toDate().toDateString(),
                            new_start_date: project.due_date.toDateString(),
                            call_to_action_text: project_name,
                            call_to_action_url: call_to_action_url,
                        }
                    }
                };
                batch.set(newEmailRef, email_data);
            }
        } else {
            console.warn('Skipping email notification for TEST, TEST!');
        }

        if (tasks) {
            for (const task of tasks) {
                const tasksRef = projectsRef.collection('tasks').doc(task.id);
                batch.set(tasksRef, task, {merge: true});
            }
        }

        batch.commit().then(() => {
            this.globalService.saving = false;
            console.warn('DONE!');
            this.closeDialog(id);
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
            this.globalService.saving = false;
        });
    }

    updateTaskDueDates(tasks, relative_to) {
        for (const task of tasks) {
            if (task['due_relative_to_create_date']) {
                task['due_date'] = this.addDays(new Date(), task.relative_due_date);
            } else {
                task['due_date'] = this.addDays(relative_to, task.relative_due_date);
            }
        }
        return tasks;
    }

    updateTaskProviderNames(tasks, first_name, last_name) {
        for (const task of tasks) {
            task['first_name'] = first_name;
            task['last_name'] = last_name;
            task['first_name_lowercase'] = first_name.toLowerCase();
            task['last_name_lowercase'] = last_name.toLowerCase();
        }
        return tasks;
    }

    departmentChanged() {
        const department = this.projectFormGroup.get('department').value;
        if (department) {
            this.projectFormGroup.get('role').setValue(null);
        }
    }

    addDays(date, days) {
        if (!Number.isInteger(days)) {
            throw new Error('Relative due date must be an integer');
        }
        const copy = new Date(Number(date));
        copy.setDate(date.getDate() + days);
        return copy;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    closeDialog(id = null) {
        this.dialogRef.close(id);
    }

}
