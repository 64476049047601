import {Component, Input, OnInit} from '@angular/core';
import * as moment from 'moment/moment';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../../services/toasts.service';

@Component({
    selector: 'app-next-activity',
    templateUrl: './next-activity.component.html',
    styleUrls: ['./next-activity.component.scss']
})
export class NextActivityComponent implements OnInit {

    @Input() referralId;
    @Input() theme = 'light';
    loaded = false;

    tasks = [];
    initialized = false;

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService) { }

    ngOnInit() {
        this.loadTasks();
        this.initialized = true;
    }

    loadTasks() {
        if (this.referralId) {
            this.loaded = false;
            // const tomorrow = moment().subtract(1, 'days').startOf('day').toDate();
            const referralTasksRef = this.afs.collection<any>('referrals').doc(this.referralId).collection('tasks', ref =>
                ref
                    // .where('due_date', '>', tomorrow)
                    .where('completed', '==', false)
                    .orderBy('due_date', 'asc'));
            referralTasksRef.valueChanges({idField: 'id'}).subscribe((tasks) => {
                this.tasks = tasks;
                // console.log('TASKS:', this.tasks);
                this.loaded = true;
            }, error => {
                console.log(error);
                this.toastsService.showSnackBar(error, 'error', 5000);
                this.loaded = true;
            });
        }
    }

    getHistoryDate(date) {
        return moment(date).format('L');
    }

    getHistoryTooltip(date, user) {
        return `Updated ${moment(date).format('llll')} by ${user}`;
    }
}
