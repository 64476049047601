import {GlobalService} from '../services/global.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../services/toasts.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable, MatSortHeader} from '@angular/material/sort';
import {ProjectsService} from '../services/projects.service';
import {GroupsService} from '../services/groups.service';
import {Subscription} from 'rxjs';
import {AuthService} from '../auth/auth.service';
import {ReferralDialogComponent} from './referral-dialog/referral-dialog';
import {ReferralsService} from '../services/referrals.service';
import {ExportDataDialogComponent} from '../projects/export-data-dialog/export-data-dialog';
import {Location} from '@angular/common';


@Component({
    selector: 'app-referrals',
    templateUrl: './referrals.component.html',
    styleUrls: ['./referrals.component.scss']
})
export class ReferralsComponent implements OnInit, AfterViewInit, OnDestroy {
    openReferralId;
    openReferralDialogRef;

    referrals = [];
    referralsSummaries = [];
    loading = true;
    membershipsLoaded = false;
    viewReferredByMe = false;
    viewMyWaitlist = false;
    viewActive = false;
    viewAssigned = false;
    viewOptedOut = false;
    viewWaitlisted = false;
    viewVisitBooked = false;
    viewNeedsAttention = false;
    viewUrgentOnly = false;
    includeFinalStatuses = false;
    selectedReferringProvider = null;
    selectedProviderPreference = null;
    selectedInsurance = null;
    selectedPrimarySpecialty = null;
    selectedLocation = null;
    selectedTaskName = null;
    selectedAssignedGroup = null;
    selectedAge = null;
    selectedOfficeCode = null;
    selectedType = null;
    selectedSubstatus = null;
    selectedMediumPreference = null;
    selectedPatientPreference = null;

    selectedReferralStatuses = [];
    selectedInsuranceStatuses = [];
    selectedAgeGroup = null;

    membershipsLoadedSubscription: Subscription;
    providersSubscription: Subscription;
    insurancesSubscription: Subscription;
    specialtiesSubscription: Subscription;

    referralsDataSource = new MatTableDataSource(null);
    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    referralsColumns: string[] = [
        'full_name',
        // 'last_name',
        'created',
        // 'visit',
        'additional_information',
        'next_activity',
        'status',
    ];

    ages = [
        'Within last week',
        'Within last month',
    ];

    mediums = [
        'Face to face',
        'Virtual',
    ];

    patients = [
        'New',
        'Existing',
    ];

    providers = [];
    insurances = {};
    specialties = {};

    office_codes = ['Unknown', '133003', '138325'];

    constructor(private router: Router,
                public globalService: GlobalService,
                private groupsService: GroupsService,
                public referralsService: ReferralsService,
                public projectsService: ProjectsService,
                public dialog: MatDialog,
                private http: HttpClient,
                private route: ActivatedRoute,
                private afs: AngularFirestore,
                private toastsService: ToastsService,
                private location: Location,
                public authService: AuthService) {
    }

    ngOnInit() {
        const referralId = this.route.snapshot.paramMap.get('id');
        this.openReferralId = referralId;

        this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
            this.membershipsLoaded = loaded;
            if (this.membershipsLoaded) {
                this.handleRouteAuthentication();
            }
        });
        this.insurancesSubscription = this.referralsService.insurances$.subscribe(insurances => {
            this.insurances = insurances;
        });
        this.specialtiesSubscription = this.referralsService.specialties$.subscribe(specialties => {
            this.specialties = specialties;
        });
        this.providersSubscription = this.referralsService.providers$.subscribe(coded_providers => {
            let final_providers = [];
            for (const [code, providers] of Object.entries(coded_providers)) {
                final_providers = final_providers.concat(providers);
                final_providers = final_providers.sort(function(a, b) {
                    if ( a.name < b.name ) {
                        return -1;
                    }
                    if ( a.name > b.name ) {
                        return 1;
                    }
                    return 0;
                });
            }
            final_providers.unshift(
                {email: 'website@claritychi.com', name: 'WEBSITE'},
                {email: 'callcenter@claritychi.com', name: 'CALL CENTER'},
                {email: 'rnfrontdesk@claritychi.com', name: 'RIVER NORTH FRONT DESK'},
                {email: 'lvfrontdesk@claritychi.com', name: 'LAKEVIEW FRONT DESK'},
                {email: 'loopfrontdesk@claritychi.com', name: 'LOOP FRONT DESK'},
                {email: 'evanstonfrontdesk@claritychi.com', name: 'EVANSTON FRONT DESK'},
                {email: 'ahfrontdesk@claritychi.com', name: 'ARLINGTON HEIGHTS FRONT DESK'});
            this.providers = final_providers;
        });
        this.referralsDataSource.filterPredicate = function(data, filter: string): boolean {
            if (data['first_name'] != null && data['last_name'] != null) {
                return data['first_name'].toLowerCase().includes(filter)
                    || data['last_name'].toLowerCase().includes(filter)
                    || (data['chart_number'] != null && data['chart_number'].toLowerCase().includes(filter));
            } else {
                return false;
            }
        };
        this.loadReferrals();
    }

    editReferral(type, referral) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.disableClose = false;
        dialogConfig.width = '1080px';
        dialogConfig.maxHeight = '90vh';
        dialogConfig.panelClass = 'referral-dialog';

        if (referral) {
            dialogConfig.data = referral;
        } else {
            this.openReferralId = null;
            this.location.replaceState('/referrals');
            this.openReferralDialogRef?.close();
            dialogConfig.data = {
                type: type
            };
        }

        this.openReferralDialogRef = this.dialog.open(ReferralDialogComponent, dialogConfig);

        this.openReferralDialogRef.afterClosed().subscribe(async referralId => {
            if (referralId) {
                console.log(referralId);
            }
            this.location.replaceState('/referrals');
        });
    }

    handleRouteAuthentication() {
        // all users allowed access to referrals page
        return;

        // const allowedUsers = [
        //     'teftekhar@claritychi.com',
        //     'slott@claritychi.com',
        //     'nkittiviriya@claritychi.com',
        // ];
        //
        // if (allowedUsers.includes(this.authService.user.email)) {
        //     return;
        // }

        // const allowedGroups = [
        //     'practicemanagermaster@claritychi.com',
        //     'practicemanagermaster@claritychi.com',
        // ];
        //
        // for (const group of this.groupsService.currentUsersGroups) {
        //     if (allowedGroups.includes(group)) {
        //         return;
        //     }
        // }

        // this.router.navigate(['help']);
    }

    exportReport() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            url: 'export-referrals-report',
            filename: 'referrals_report.csv'
        };

        const dialogRef = this.dialog.open(ExportDataDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    exportData() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = false;
        dialogConfig.data = {
            url: 'export-referrals-data',
            filename: 'referrals_data.csv'
        };

        const dialogRef = this.dialog.open(ExportDataDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                console.log(result);
            }
        });
    }

    ngAfterViewInit() {
        this.referralsDataSource.sortingDataAccessor = (data, sortHeaderId) => {
            switch (sortHeaderId) {
                case 'full_name': return (data['last_name'].trim() + ',' + data['first_name'].trim()).toLocaleLowerCase();
                // case 'last_name': return data['last_name'].toLocaleLowerCase();
                case 'created': return data['created'];
                case 'additional_information': return this.getLastActivityDateForReferral(data);
                default: return data[sortHeaderId].toLocaleLowerCase();
            }
        };
        this.referralsDataSource.paginator = this.paginator;
    }

    getLastActivityDateForReferral(referral) {
        if (referral['history'] && referral['history'].length) {
            const lastAction = referral['history'][referral['history'].length - 1];
            return lastAction['changed_at'].toDate();
        } else {
            return referral['created'];
        }
    }

    loadReferrals() {
        this.loading = true;
        let firstLoad = true;
        this.referralsDataSource.data = [];
        // TODO only load what's being filtered
        // currently we only load last 6 months of data
        const referralsCollection = this.afs.collection<any>('referrals', ref => ref.where('created', '>', moment().subtract(6, 'months').toDate()).orderBy('created', 'desc'));
        referralsCollection.valueChanges({idField: 'id'}).subscribe((referrals) => {
            // iterate over all referrals, and then iterate over all history items within each referral
            // remove any history items where the action.status === 10
            // TODO if we want to include task history in the history list, we can join it to the tasks that are loaded and use created at date
            referrals.forEach(referral => {
                if (referral['history'] && referral['history'].length) {
                    referral['history'] = referral['history'].filter(history => history['action']['status'] !== 10);
                }
            });

            this.referrals = referrals;

            // TODO only do this math for PHP/IOP views, automate this better for other types separately
            this.updateReferralSummaries(this.selectedType);
            if (firstLoad) {
                this.sort.sort(({id: 'created', start: 'desc'}) as MatSortable);
            }
            this.referralsDataSource.sort = this.sort;
            this.referralsDataSource.data = referrals;
            (this.sort.sortables.get('created') as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
            this.updateView();
            this.loading = false;
            if (firstLoad) {
                this.openReferralDialog();
            }
            firstLoad = false;
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    openReferralDialog() {
        if (this.openReferralId && this.referrals.length) {
            const referral = this.findReferral(this.openReferralId);
            if (referral) {
                this.editReferral(referral.type, referral);
            }
        } else {
            this.closeReferralDialog();
        }
    }

    closeReferralDialog() {
        if (this.openReferralDialogRef) {
            this.openReferralDialogRef.close();
        }
    }

    navigateToReferral(referral) {
        this.location.replaceState(`/referrals/${referral.id ? referral.id : ''}`);
        this.editReferral(referral.type, referral);
    }

    findReferral(referralId) {
        return this.referrals.find(referral => referral.id === referralId);
    }

    applyFilter(filterValue: string) {
        this.referralsDataSource.filter = filterValue.trim().toLowerCase();
    }

    toggleNeedsAttention() {
        this.viewNeedsAttention = !this.viewNeedsAttention;
        this.updateView();
    }

    toggleUrgentOnly() {
        this.viewUrgentOnly = !this.viewUrgentOnly;
        this.updateView();
    }

    toggleViewVisitBooked() {
        this.viewVisitBooked = !this.viewVisitBooked;
        this.updateView();
    }

    toggleViewOptedOut() {
        this.viewOptedOut = !this.viewOptedOut;
        this.updateView();
    }

    toggleViewWaitlisted() {
        this.viewWaitlisted = !this.viewWaitlisted;
        this.updateView();
    }

    toggleViewActive() {
        this.viewActive = !this.viewActive;
        this.updateView();
    }

    toggleIncludeFinalStatuses() {
        this.includeFinalStatuses = !this.includeFinalStatuses;
        this.updateReferralSummaries(this.selectedType);
        this.updateView();
    }

    toggleViewReferredByMe() {
        this.viewReferredByMe = !this.viewReferredByMe;
        if (this.viewReferredByMe) {
            this.selectedReferringProvider = this.authService.user.email.toUpperCase();
        } else {
            this.selectedReferringProvider = null;
        }
        this.updateView();
    }

    toggleViewAssigned() {
        this.viewAssigned = !this.viewAssigned;
        this.updateView();
    }

    toggleViewMyWaitlist() {
        this.viewMyWaitlist = !this.viewMyWaitlist;
        if (this.viewMyWaitlist) {
            this.selectedProviderPreference = this.authService.user.email.toUpperCase();
        } else {
            this.selectedProviderPreference = null;
        }
        this.updateView();
    }

    selectReferringOfficeCode(code: any) {
        this.selectedOfficeCode = code;
        this.updateView();
    }

    selectReferringProvider(provider: any) {
        this.selectedReferringProvider = provider;
        this.updateView();
    }

    selectProviderPreference(provider: any) {
        this.selectedProviderPreference = provider;
        console.log(this.selectedProviderPreference);
        this.updateView();
    }

    selectInsurance(insurance: any) {
        this.selectedInsurance = insurance;
        this.updateView();
    }

    selectMediumPreference(medium: any) {
        this.selectedMediumPreference = medium;
        this.updateView();
    }

    selectPatientPreference(patient: any) {
        this.selectedPatientPreference = patient;
        this.updateView();
    }

    selectPrimarySpecialty(specialty: any) {
        this.selectedPrimarySpecialty = specialty;
        this.updateView();
    }

    selectAge(age: string) {
        this.selectedAge = age;
        this.updateView();
    }

    selectLocation(location: string) {
        this.selectedLocation = location;
        this.updateView();
    }

    selectType(type: string) {
        this.selectedType = type;
        // clear this.selectedReferralStatuses and this.selectedInsuranceStatuses
        this.selectedReferralStatuses = [];
        this.selectedInsuranceStatuses = [];
        this.updateReferralSummaries(type);
        this.updateView();
    }

    selectSubstatus(status: string) {
        this.selectedSubstatus = status;
        this.updateView();
    }

    selectReferralStatus(status: number) {
        this.updateView();
    }

    selectInsuranceStatus(status: number) {
        this.updateView();
    }

    selectAgeGroup(ageGroup: string) {
        this.selectedAgeGroup = ageGroup;
        this.updateView();
    }

    updateReferralSummaries(selectedType) {
        if (this.selectedType && ['php-iop', 'tms'].includes(this.selectedType)) {
            this.referralsSummaries = this.referralsService.getReferralsSummary(this.referrals, selectedType, this.includeFinalStatuses);
        }
    }

    updateView() {
        let filteredReferrals = this.referrals;

        // filteredReferrals = filteredReferrals.filter(p => p['created_by'] === 'Website');

        if (this.viewAssigned) {
            filteredReferrals = filteredReferrals.filter(p => p['assigned_to'] === this.authService.user.email);
        }

        if (this.selectedAgeGroup) {
            filteredReferrals = filteredReferrals.filter(p => p['age_group'] === this.selectedAgeGroup);
        }

        if (['php-iop', 'tms'].includes(this.selectedType) && !this.includeFinalStatuses) {
            filteredReferrals = filteredReferrals.filter(p => {
                const status_definition = this.referralsService.getStatusDefinition(this.selectedType, 'referral_status', p['referral_status']);
                return !status_definition?.is_final;
            });
        }

        if (this.viewVisitBooked) {
            filteredReferrals = filteredReferrals.filter(p => p['status'] === 'Booked');
        }

        if (this.viewNeedsAttention) {
            filteredReferrals = filteredReferrals.filter(p =>
                p['response'] === 'Help'
                || p['response'] === 'Undelivered'
                || p['response'] === 'Failed'
                || p['important'] === true
                || p['urgent'] === true
                // || (p['status'] === 'Active' && p['sms_enabled'] === false)
                || (p['type'] === 'tms' && p['status'] === 'Active' && (p['prior_auth_approved'] !== true || p['medical_records_received'] !== true)));
        }

        if (this.viewUrgentOnly) {
            filteredReferrals = filteredReferrals.filter(p => p['urgent'] === true);
        }

        if (this.viewOptedOut) {
            filteredReferrals = filteredReferrals.filter(p => p['status'] === 'Declined');
        }

        if (this.viewWaitlisted) {
            filteredReferrals = filteredReferrals.filter(p => p['status'] === 'Waitlisted');
        }

        if (this.viewActive) {
            filteredReferrals = filteredReferrals.filter(p => p['status'] === 'Active');
        }

        if (this.selectedType) {
            filteredReferrals = filteredReferrals.filter(p => p['type'] === this.selectedType);
        }

        if (this.selectedReferringProvider) {
            filteredReferrals = filteredReferrals.filter(p => p['referring_provider'] === this.selectedReferringProvider);
        }

        if (this.selectedProviderPreference) {
            filteredReferrals = filteredReferrals.filter(p => p['provider_preference'] === this.selectedProviderPreference);
        }

        if (this.selectedInsurance) {
            filteredReferrals = filteredReferrals.filter(p => p['insurance'] === this.selectedInsurance);
        }

        if (this.selectedSubstatus !== null) {
            filteredReferrals = filteredReferrals.filter(p => p['substatus'] === this.selectedSubstatus);
        }

        if (this.selectedReferralStatuses.length) {
            filteredReferrals = filteredReferrals.filter(p => this.selectedReferralStatuses.includes(p['referral_status']));
        }

        if (this.selectedInsuranceStatuses.length) {
            filteredReferrals = filteredReferrals.filter(p => this.selectedInsuranceStatuses.includes(p['insurance_status']));
        }

        if (this.selectedMediumPreference) {
            filteredReferrals = filteredReferrals.filter(p => p['medium_preference'] === this.selectedMediumPreference);
        }

        if (this.selectedPatientPreference) {
            if (this.selectedPatientPreference === 'New') {
                filteredReferrals = filteredReferrals.filter(p => p['appointment_type_preference'] && p['appointment_type_preference']?.toUpperCase()?.includes('INIT'));
            } else if (this.selectedPatientPreference === 'Existing') {
                filteredReferrals = filteredReferrals.filter(p => p['appointment_type_preference'] && !p['appointment_type_preference']?.toUpperCase()?.includes('INIT'));
            }
        }

        if (this.selectedPrimarySpecialty) {
            filteredReferrals = filteredReferrals.filter(p => p['primary_specialty'] === this.selectedPrimarySpecialty);
        }

        if (this.selectedLocation) {
            filteredReferrals = filteredReferrals.filter(p => p['location_preference'] === this.selectedLocation);
        }

        if (this.selectedOfficeCode !== null) {
            let office_code_to_check = '';
            if (this.selectedOfficeCode === 'Unknown') {
                office_code_to_check = '';
            } else {
                office_code_to_check = this.selectedOfficeCode;
            }
            filteredReferrals = filteredReferrals.filter(p => p['office_code'] === office_code_to_check);
        }

        if (this.selectedAge) {
            let cutoff;
            if (this.selectedAge === this.ages[0]) {
                cutoff = moment().subtract(7, 'days');
            } else if (this.selectedAge === this.ages[1]) {
                cutoff = moment().subtract(30, 'days');
            }
            filteredReferrals = filteredReferrals.filter(p => moment(p['created'].toDate()).isAfter(cutoff));
        }

        this.referralsDataSource.data = filteredReferrals;
    }

    filterChanged(event) {
        // TODO refactor to be more generic for any stage options
        const filter_name = event.name;
        const value = event.value;
        if (filter_name === 'insurance_status') {
            // add value to selectedInsuranceStatuses and remove duplicates
            if (this.selectedInsuranceStatuses.includes(value)) {
                this.selectedInsuranceStatuses = [...this.selectedInsuranceStatuses.filter(item => item !== value)];
            } else {
                const updated = [...this.selectedInsuranceStatuses, value];
                this.selectedInsuranceStatuses = updated;
            }
        } else if (filter_name === 'referral_status') {
            // add value to selectedReferralStatuses and remove duplicates
            if (this.selectedReferralStatuses.includes(value)) {
                this.selectedReferralStatuses = [...this.selectedReferralStatuses.filter(item => item !== value)];
            } else {
                const updated = [...this.selectedReferralStatuses, value];
                console.log(updated);
                this.selectedReferralStatuses = updated;
            }
        }
        this.updateView();
    }

    ngOnDestroy() {
        this.membershipsLoadedSubscription.unsubscribe();
        this.providersSubscription.unsubscribe();
        this.insurancesSubscription.unsubscribe();
        this.specialtiesSubscription.unsubscribe();
    }

}
