import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../environments/environment.prod';
import {HttpClient, HttpParams} from '@angular/common/http';
import {ToastsService} from './toasts.service';
import * as moment from 'moment';
import {AngularFireFunctions} from '@angular/fire/functions';
import {GlobalService} from './global.service';
import {FirebaseService} from './firebase.service';
import {AuthService} from '../auth/auth.service';
import {GroupsService} from './groups.service';
import {AngularFirestore} from '@angular/fire/firestore';
// import momentBusinessDays from 'moment-business-days';


@Injectable({
    providedIn: 'root'
})
export class ReferralsService {

    private readonly _providers = new BehaviorSubject<{}>({});
    readonly providers$ = this._providers.asObservable();

    get providers(): {} {
        return this._providers.getValue();
    }

    set providers(val: {}) {
        this._providers.next(val);
    }

    private readonly _specialties = new BehaviorSubject<{}>({});
    readonly specialties$ = this._specialties.asObservable();

    get specialties(): {} {
        return this._specialties.getValue();
    }

    set specialties(val: {}) {
        this._specialties.next(val);
    }

    private readonly _treatment_types = new BehaviorSubject<any[]>([]);
    readonly treatment_types$ = this._treatment_types.asObservable();

    get treatment_types() {
        return this._treatment_types.getValue();
    }

    set treatment_types(val) {
        this._treatment_types.next(val);
    }

    private readonly _insurances = new BehaviorSubject<{}>({});
    readonly insurances$ = this._insurances.asObservable();

    get insurances(): {} {
        return this._insurances.getValue();
    }

    set insurances(val: {}) {
        this._insurances.next(val);
    }

    private readonly _appointmentTypeDetails = new BehaviorSubject<{}>({});
    readonly appointmentTypeDetails$ = this._appointmentTypeDetails.asObservable();

    get appointmentTypeDetails(): {} {
        return this._appointmentTypeDetails.getValue();
    }

    set appointmentTypeDetails(val: {}) {
        this._appointmentTypeDetails.next(val);
    }

    private readonly _group_therapy_settings = new BehaviorSubject<any[]>([]);
    readonly group_therapy_settings$ = this._group_therapy_settings.asObservable();

    get group_therapy_settings() {
        return this._group_therapy_settings.getValue();
    }

    set group_therapy_settings(val) {
        this._group_therapy_settings.next(val);
    }

    office_codes = ['133003', '138325'];

    types = [
        {
            label: 'Therapy',
            value: 'therapy'
        },
        {
            label: 'Psychiatry',
            value: 'psychiatry'
        },
        {
            label: 'TMS',
            value: 'tms'
        },
        {
            label: 'PHP/IOP',
            value: 'php-iop'
        },
        {
            label: 'Group',
            value: 'group'
        }
    ];

    pronouns = [
        {
            label: 'He/Him/His',
            value: 'he/him/his'
        },
        {
            label: 'She/Her/Hers',
            value: 'she/her/hers'
        },
        {
            label: 'They/Them/Theirs',
            value: 'they/them/theirs'
        },
        {
            label: 'Prefer Not To Say',
            value: 'prefer not to say'
        }
    ];

    tmsSubstatuses = [
        'Medical Hx Gathered',
        'PA Issued',
        'Educational Session Scheduled',
    ];

    // TODO these are the new statuses for PHP/IOP referrals
    // TODO move actions outside so it is universal for all referrals
    lead_definitions = {
        'php-iop': {
            'statuses': {
                'referral_status': {
                    label: 'Referral Status',
                    previous_status: 0,
                    name: 'referral_status',
                    statuses: [
                        {
                            label: 'New',
                            value: 0,
                            order: 0,
                            details: [],
                        },
                        {
                            label: 'Attempting',
                            value: 1,
                            order: 1,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Connected',
                            value: 2,
                            order: 2,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Assessment Scheduled',
                            value: 3,
                            order: 3,
                            details: [
                                {
                                    label: 'Initial Assessment Date',
                                    name: 'assessment_at',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Assessment Completed',
                            value: 6,
                            order: 4,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Patient Admitted',
                            value: 4,
                            order: 5,
                            is_final: true,
                            details: [
                                {
                                    label: 'Program',
                                    name: 'program',
                                    type: 'select',
                                    options: [
                                        'IOP',
                                        'PHP',
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'Program Start Date',
                                    name: 'program_start_at',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'AMD Referral Status Updated',
                                    name: 'amd_referral_status_updated',
                                    type: 'checkbox',
                                    hint: 'On the AMD Referral, Status must be set to "AUTH REQUIRED" or "NO AUTH REQUIRED"',
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                            ]
                        },
                        {
                            label: 'Inactive',
                            value: 5,
                            order: 6,
                            is_final: true,
                            details: [
                                {
                                    label: 'Reason',
                                    name: 'reason',
                                    type: 'select',
                                    options: [
                                        'Insurance Denied',
                                        'Patient Declined',
                                        'Patient Declined - Financial Issue',
                                        'Patient Declined - Transportation Issue',
                                        'Patient Declined - Schedule Issue',
                                        'Patient Declined - Competing Program Issue',
                                        'Patient Not Appropriate For Care',
                                        'Other'
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        }
                    ]
                },
                'insurance_status': {
                    label: 'Insurance Status',
                    previous_status: 0,
                    name: 'insurance_status',
                    statuses: [
                        {
                            label: 'New',
                            value: 0,
                            order: 0,
                            details: [],
                        },
                        {
                            label: 'Chart Updated',
                            value: 6,
                            order: 1,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                                {
                                    label: 'Insurance Updated in Chart',
                                    name: 'insurance_updated',
                                    type: 'checkbox',
                                    required: true,
                                },
                                {
                                    label: 'UB Insurance Updated in Chart',
                                    name: 'ub_insurance_updated',
                                    type: 'checkbox',
                                    hint: 'The UB version of the patient\'s insurance must be added to the chart',
                                    required: true,
                                },
                                {
                                    label: 'Financial Class Updated',
                                    name: 'financial_class_updated',
                                    type: 'checkbox',
                                    hint: 'The patient\'s financial class should match the updated insurance details',
                                    required: true,
                                },
                            ]
                        },
                        {
                            label: 'Insurance Verified',
                            value: 1,
                            order: 2,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                                {
                                    label: 'Verification Phone Call Documented',
                                    name: 'insurance_verification_documented',
                                    type: 'checkbox',
                                    hint: 'Call details must be logged in the PHP/IOP Custom Tab on the patient\'s chart',
                                    required: true,
                                },
                            ]
                        },
                        {
                            label: 'AMD Referral Created',
                            value: 2,
                            order: 3,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                                {
                                    label: 'Reason Updated',
                                    name: 'amd_referral_reason_updated',
                                    type: 'checkbox',
                                    hint: 'On the AMD Referral, Reason should be set to "PHP/IOP"',
                                    required: true,
                                },
                                {
                                    label: 'Status Updated',
                                    name: 'amd_referral_status_updated',
                                    type: 'checkbox',
                                    hint: 'Status must start at "#READY FOR USE"',
                                    required: true,
                                },
                                {
                                    label: 'Referred By Updated',
                                    name: 'amd_referral_referring_provider_updated',
                                    type: 'checkbox',
                                    hint: 'On the AMD Referral, Referred By must be program director, e.g. "BLACK, ELIZABETH"',
                                    required: true,
                                },
                                {
                                    label: 'Insurance Carrier Updated',
                                    name: 'amd_referral_insurance_carrier_updated',
                                    type: 'checkbox',
                                    hint: 'On the AMD Referral, Insurance carrier must be set to the UB insurance',
                                    required: true,
                                },
                                {
                                    label: 'Include Referral On Claim Checked',
                                    name: 'amd_referral_include_referral_on_claim_checked',
                                    type: 'checkbox',
                                    hint: 'On the AMD Referral, "Include Referral On Claim" must be checked',
                                    required: true,
                                },
                            ]
                        },
                        {
                            label: 'Episode Created',
                            value: 3,
                            order: 4,
                            is_final: true,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                                {
                                    label: 'Insurance Order Updated',
                                    name: 'episode_insurance_order_updated',
                                    type: 'checkbox',
                                    hint: 'On the Episode, the Insurance Order must point to the UB insurance',
                                    required: true,
                                },
                                {
                                    label: 'Financial Class Updated',
                                    name: 'episode_financial_class_updated',
                                    type: 'checkbox',
                                    hint: 'On the Episode, the Financial Class must match the UB insurance',
                                    required: true,
                                },
                                {
                                    label: 'Episode Linked to AMD Referral',
                                    name: 'episode_linked_to_amd_referral',
                                    type: 'checkbox',
                                    hint: 'The Episode must be linked to the newly created AMD Referral for PHP/IOP',
                                    required: true,
                                },
                            ]
                        },
                    ]
                },
            },
            // SYSTEM GENERATED
            'actions': {
                label: 'Actions',
                name: 'actions',
                options: [
                    {
                        label: 'Log Call',
                        label_past_tense: 'Logged a call',
                        value: 0,
                        details: [
                            {
                                label: 'Call Made On',
                                name: 'call_made_at',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Outcome',
                                name: 'outcome',
                                type: 'select',
                                options: [
                                    'Connected',
                                    'Left Voicemail',
                                    'Other'
                                ],
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ],
                    },
                    {
                        label: 'Log SMS',
                        label_past_tense: 'Logged an SMS',
                        value: 1,
                        details: [
                            {
                                label: 'SMS Sent On',
                                name: 'sms_sent_at',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'Log Email',
                        label_past_tense: 'Logged an email',
                        value: 2,
                        details: [
                            {
                                label: 'Email Sent On',
                                name: 'email_sent_at',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'Log Note',
                        label_past_tense: 'Logged a note',
                        value: 3,
                        details: [
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                                required: true,
                            }
                        ]
                    },
                    {
                        label: 'Schedule Touchpoint',
                        label_past_tense: 'Scheduled a touchpoint',
                        value: 4,
                        deprecated: true,
                        details: [
                            {
                                label: 'Scheduled For',
                                name: 'touchpoint_scheduled_for',
                                type: 'date',
                                is_dropdown: true,
                                required: true,
                            },
                            {
                                label: 'Due Date',
                                name: 'due_date',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'Create Task',
                        label_past_tense: 'Task created',
                        value: 10,
                        details: [
                            {
                                label: 'Type',
                                name: 'type',
                                type: 'select',
                                options: [
                                    'To-Do',
                                    'Phone Call',
                                    'SMS',
                                    'Email',
                                ],
                                default_first_option: true,
                                required: true,
                            },
                            {
                                label: 'Due Date',
                                name: 'due_date',
                                type: 'date',
                                is_dropdown: true,
                                required: true,
                            },
                            {
                                label: 'Assigned To',
                                name: 'assigned_to',
                                type: 'user',
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            },
                        ]
                    },
                ]
            }
        },
        'tms': {
            'statuses': {
                'referral_status': {
                    label: 'Referral Status',
                    previous_status: 0,
                    name: 'referral_status',
                    statuses: [
                        {
                            label: 'New',
                            value: 0,
                            order: 0,
                            details: [],
                        },
                        {
                            label: 'Attempting',
                            value: 1,
                            order: 1,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Connected',
                            value: 2,
                            order: 2,
                            details: [
                                {
                                    label: 'Educational Session Occurred',
                                    name: 'educational_session_occurred',
                                    type: 'checkbox',
                                    required: false,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Psych Eval Scheduled',
                            value: 3,
                            order: 3,
                            details: [
                                {
                                    label: 'Psych Eval Date',
                                    name: 'psych_eval_at',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Treatment Started',
                            value: 4,
                            order: 4,
                            details: [
                                {
                                    label: 'Program Start Date',
                                    name: 'program_start_at',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                            ]
                        },
                        {
                            label: 'Treatment Completed',
                            value: 5,
                            order: 5,
                            is_final: true,
                            details: [
                                {
                                    label: 'Program Complete Date',
                                    name: 'program_completed_at',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                },
                            ]
                        },
                        {
                            label: 'Inactive',
                            value: 6,
                            order: 6,
                            is_final: true,
                            details: [
                                {
                                    label: 'Reason',
                                    name: 'reason',
                                    type: 'select',
                                    options: [
                                        'Insurance Denied',
                                        'Patient Declined',
                                        'Patient Declined - Financial Issue',
                                        'Patient Declined - Transportation Issue',
                                        'Patient Declined - Schedule Issue',
                                        'Patient Declined - Competing Program Issue',
                                        'Patient Not Appropriate For Care',
                                        'Other'
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        }
                    ]
                },
                'insurance_status': {
                    label: 'Insurance Status',
                    previous_status: 0,
                    name: 'insurance_status',
                    statuses: [
                        {
                            label: 'New',
                            value: 0,
                            order: 0,
                            details: [],
                        },
                        {
                            label: 'Med History Gathered',
                            value: 1,
                            order: 1,
                            details: [
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Prior Auth Submitted',
                            value: 2,
                            order: 2,
                            details: [
                                {
                                    label: 'Prior Auth Submission Date',
                                    name: 'prior_auth_submitted_at',
                                    type: 'date',
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                        {
                            label: 'Prior Auth Completed',
                            value: 3,
                            order: 3,
                            details: [
                                {
                                    label: 'Outcome',
                                    name: 'outcome',
                                    type: 'select',
                                    options: [
                                        'Approved',
                                        'Denied',
                                        'Not Needed'
                                    ],
                                    required: true,
                                },
                                {
                                    label: 'Notes',
                                    name: 'notes',
                                    type: 'text',
                                }
                            ]
                        },
                    ],
                }
            },
            // SYSTEM GENERATED
            'actions': {
                label: 'Actions',
                name: 'actions',
                options: [
                    {
                        label: 'Log Call',
                        label_past_tense: 'Logged a call',
                        value: 0,
                        details: [
                            {
                                label: 'Call Made On',
                                name: 'call_made_at',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Outcome',
                                name: 'outcome',
                                type: 'select',
                                options: [
                                    'Connected',
                                    'Left Voicemail',
                                    'Other'
                                ],
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ],
                    },
                    {
                        label: 'Log SMS',
                        label_past_tense: 'Logged an SMS',
                        value: 1,
                        details: [
                            {
                                label: 'SMS Sent On',
                                name: 'sms_sent_at',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'Log Email',
                        label_past_tense: 'Logged an email',
                        value: 2,
                        details: [
                            {
                                label: 'Email Sent On',
                                name: 'email_sent_at',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'Log Note',
                        label_past_tense: 'Logged a note',
                        value: 3,
                        details: [
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                                required: true,
                            }
                        ]
                    },
                    {
                        label: 'Schedule Touchpoint',
                        label_past_tense: 'Scheduled a touchpoint',
                        value: 4,
                        deprecated: true,
                        details: [
                            {
                                label: 'Scheduled For',
                                name: 'touchpoint_scheduled_for',
                                type: 'date',
                                is_dropdown: true,
                                required: true,
                            },
                            {
                                label: 'Due Date',
                                name: 'due_date',
                                type: 'date',
                                required: true,
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            }
                        ]
                    },
                    {
                        label: 'Create Task',
                        label_past_tense: 'Task created',
                        value: 10,
                        details: [
                            {
                                label: 'Type',
                                name: 'type',
                                type: 'select',
                                options: [
                                    'To-Do',
                                    'Phone Call',
                                    'SMS',
                                    'Email',
                                ],
                                default_first_option: true,
                                required: true,
                            },
                            {
                                label: 'Due Date',
                                name: 'due_date',
                                type: 'date',
                                is_dropdown: true,
                                required: true,
                            },
                            {
                                label: 'Assigned To',
                                name: 'assigned_to',
                                type: 'user',
                            },
                            {
                                label: 'Notes',
                                name: 'notes',
                                type: 'text',
                            },
                        ]
                    },
                ]
            }
        }
    };

    // iterate over the lead_definitions.statuses which is a map of stages. each stage is another object. add the key for the stage to the obkect, then return as part of a new array
    // this array will be used to iterate over the stages in the UI
    phpIopReferralStages = Object.keys(this.lead_definitions['php-iop']['statuses']).map(key => {
        return {
            name: key,
            ...this.lead_definitions['php-iop']['statuses'][key]
        };
    });

    tmsReferralStages = Object.keys(this.lead_definitions['tms']['statuses']).map(key => {
        return {
            name: key,
            ...this.lead_definitions['tms']['statuses'][key]
        };
    });

    dueDateOptionsWithBusinessDays = 3;
    dueDateOptions = [
        {label: 'in 1 day', value: moment().add(1, 'day').toDate()},
        {label: 'in 2 days', value: moment().add(2, 'day').toDate()},
        {label: 'in 3 days', value: moment().add(3, 'day').toDate()},
        {label: 'in 1 week', value: moment().add(1, 'week').toDate()},
        {label: 'in 2 weeks', value: moment().add(2, 'week').toDate()},
        {label: 'in 1 month', value: moment().add(1, 'month').toDate()},
        {label: 'in 3 months', value: moment().add(3, 'month').toDate()},
        {label: 'in 1 year', value: moment().add(1, 'year').toDate()},
    ];

    populateDueDateLabels() {
        // for each dueDateOption, set the label to be a human-readable string, by adding the day of the week to the existing label
        // for example for the first option, the label should be "in 1 business day (Tuesday)" if 1 business day from today is Tuesday
        // only do this for the first 3 options
        this.dueDateOptions.slice(0, this.dueDateOptionsWithBusinessDays).forEach(option => {
            option.label = `${option.label} (${moment(option.value).format('dddd')})`;
        });
    }


    constructor(private globalService: GlobalService, private cloudFunctions: AngularFireFunctions, private toastsService: ToastsService, private http: HttpClient, private firebaseService: FirebaseService, private authService: AuthService, private groupsService: GroupsService, private afs: AngularFirestore) {
        this.populateDueDateLabels();
        this.getProviders();
        this.getProviderFields();
        this.getAppointmentTypeDetails();
        this.getGroupTherapySettings();
    }

    getProviders() {
        this.providers = {};
        for (const code of this.office_codes) {
            this.providers[code] = [];
            this.getProvidersAtOfficeCode(code);
        }
    }

    getProviderFields() {
        this.specialties = {};
        this.insurances = {};
        this.treatment_types = [];
        const url = environment.apiBaseUrl + 'referrals/filters';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                this.specialties = result['body']['specialties'].reduce(
                    (obj, item) => Object.assign(obj, { [item.slug]: item.label }), {});
                this.insurances = result['body']['insurances'].reduce(
                    (obj, item) => Object.assign(obj, { [item.slug]: item.label }), {});
                // console.log(this.specialties, this.insurances);
                delete this.insurances['humana-ppo-epo'];
                delete this.insurances['tricare'];
                this.treatment_types = this.getTreatmentTypes();
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading treatment areas');
        });
    }

    getAppointmentTypeDetails() {
        this.appointmentTypeDetails = {};
        const apptTypes = this.firebaseService.firebaseAmd.collection<any>('amd').doc('appointment-types-details');
        apptTypes.valueChanges().subscribe((appt_types) => {
            this.appointmentTypeDetails = appt_types;
            this.findGroupLeaders();
        }, error => {
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    findGroupLeaders() {
        for (const [office_code, settings] of Object.entries(this.appointmentTypeDetails)) {
            for (const [appointment_type_id, appointment_type_settings] of Object.entries(settings)) {
                if (appointment_type_settings['is_group']) {
                    appointment_type_settings['group_leaders_emails'] = [];
                    for (const provider of this.providers[office_code]) {
                        if (appointment_type_settings['group_leaders'].includes(provider['profile_id'])) {
                            appointment_type_settings['group_leaders_emails'].push(provider['email']);
                        }
                    }
                }
            }
        }
    }

    getTreatmentTypes() {
        return [
            {'adults': 'Adults'},
            {'minors': 'Minors'},
            {'minors-adolescents-3': '(DO NOT USE AFTER 10/2023) Minors/Adolescents (Ages 3-17)'},
            {'minors-adolescents-10': '(DO NOT USE AFTER 10/2023) Minors/Adolescents (Ages 10-17)'},
            {'minors-adolescents-15': '(DO NOT USE AFTER 10/2023) Young Adult (Ages 15-17)'},
            {'families': 'Families'},
            {'couples': 'Couples'},
            {'groups': 'Groups'}
        ];
    }

    getGroupTherapySettings() {
        this.group_therapy_settings = [];
        const url = environment.apiBaseUrl + 'group-therapy-settings';
        this.http.get(url, {observe : 'response'}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                this.group_therapy_settings = result.body[0].settings;
                // console.log(this.group_therapy_settings);
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading group therapy settings');
        });
    }

    getProvidersAtOfficeCode(office_code) {
        const params = new HttpParams().set('office_code', office_code);
        const url = environment.amdApiBaseUrl + 'get-all-providers';
        this.http.get(url, {observe : 'response', params: params}).subscribe(result => {
            if (result.status === 200) {
                // @ts-ignore
                const providers = result.body.sort(function(a, b) {
                    if ( a.name < b.name ) {
                        return -1;
                    }
                    if ( a.name > b.name ) {
                        return 1;
                    }
                    return 0;
                });
                this.providers[office_code] = providers;
                this.providers = this.providers;
                console.warn(this.providers);
                this.findGroupLeaders();
            }
        }, error => {
            this.toastsService.showSnackBar('Error loading providers');
        });
    }

    getReferralVisitTooltip(referral) {
        if (referral && referral.referral_visit && referral.referral_visit.appointment_type && referral.referral_visit.column_heading && referral.referral_visit.start_datetime) {
            const start_datetime = moment(referral.referral_visit.start_datetime).format('llll');
            return `${referral.referral_visit.appointment_type} with ${referral.referral_visit.column_heading} on ${start_datetime}`;
        }
        return '';
    }

    getMessagesSentTooltip(referral) {
        if (referral && referral.messages_sent && referral.last_message_date_created) {
            const last_message_sent = moment(referral.last_message_date_created.toDate()).format('llll');
            return `Last SMS sent on  ${last_message_sent}`;
        }
        return '';
    }

    getResponseReceivedTooltip(referral) {
        if (referral && referral.response && referral.response_created) {
            const reponse_received = moment(referral.response_created.toDate()).format('llll');
            return `Help requested via SMS on  ${reponse_received}`;
        }
        return '';
    }

    getMessageHistoryTooltip(isDirty = false): string {
        if (isDirty) {
            return 'Changes have been made. You must save this referral before viewing message history';
        }
        return 'Click to view 2-way SMS history with this referral';
    }

    sendSingleSMS(referral, body = null) {
        referral = JSON.parse(JSON.stringify(referral)); // needed because of the way the data is passed to the cloud function
        const sendSingleSMS = this.cloudFunctions.httpsCallable('sendSingleSMS');
        sendSingleSMS({ referral: referral, body, password: 'Clarity9404!' }).subscribe(result => {
            console.log(result);
            this.globalService.saving = false;
        }, error => {
            console.log(error);
            this.globalService.saving = false;
        });
    }

    prettyPrintReferralAction(action, type) {
        try {
            const stages = type === 'php-iop' ? this.phpIopReferralStages : this.tmsReferralStages;
            const merged = [this.lead_definitions[type]['actions']].concat([...stages]);
            const action_schema = merged.find(stage => stage.name === action.name);
            if (!action_schema) {
                if (action.pretty_printed) {
                    return action.pretty_printed;
                }
                return 'Unknown Action';
            }


            if (action.name === 'actions') {
                // find action label based on action_schema.actions
                // TODO refactor this once statuses and actions merged (.actions and .statuses are similar)
                const label = action_schema.options.find(a => a.value === action.status)?.label;
                return `${label}`;
            } else {
                // TODO refactor this once statuses and actions merged (.actions and .statuses are similar)
                const label = action_schema?.['statuses']?.find(s => s.value === action.status)?.label;
                return `${action_schema.label} changed to ${label}`;
            }
        } catch (e) {
            console.log(e);
            if (action.pretty_printed) {
                return action.pretty_printed;
            }
            return 'Unknown Action';
        }
    }

    prettyPrintAssignedTo(assigned_to) {
        if (assigned_to === null || assigned_to === undefined) {
            return 'Unknown referral assignment';
        }

        if (assigned_to === '') {
            return `Referral was unassigned`;
        }

        const assigned_to_display_name = this.groupsService.allUsersEmailMap[assigned_to]?.displayName;

        return `Referral was assigned to ${assigned_to_display_name}`;
    }

    getReferralsSummary(referrals, type, include_final_statuses = false) {
        // using type, get the schema in lead_definitions for the type
        // then for each status in statuses, get the count of referrals in that status, get the status name, and get the status label
        // add it to an array of referral summaries by status and return it
        const schema = this.lead_definitions[type];
        const summaries = [];

        // schema.statuses is an object, with each stage having a unique key
        // we need to iterate over the keys to get the stages
        for (const stage_key in schema.statuses) {
            if (schema.statuses.hasOwnProperty(stage_key)) {
                const stage = schema.statuses[stage_key];
                // each stage has a statuses key, which is an array of statuses
                // we need to iterate over the statuses to get the count of referrals in that status, also the status name and label
                const root_status = {
                    name: stage.name,
                    label: stage.label,
                    count: 0,
                    statuses: {},
                };
                const final_statuses = stage.statuses.filter(status => status.is_final);
                for (const status_key in stage.statuses) {
                    // we only want to count statuses that are not final states
                    if (stage.statuses.hasOwnProperty(status_key) && !stage.statuses[status_key]?.is_final) {
                        const status_schema = stage.statuses[status_key];
                        // console.log(status_schema);
                        const count = referrals.filter(referral => referral.type === type && referral[stage.name] === status_schema.value && (include_final_statuses || !final_statuses.includes(referral['referral_status']))).length;
                        root_status.count += count;
                        root_status.statuses[status_key] = {
                            count: count,
                            label: status_schema.label,
                            value: status_schema.value
                        };
                    }
                }

                summaries.push(root_status);
            }
        }

        return summaries;
    }

    async createNewTask(referral_id, task_details) {
        try {
            const task = {
                ...task_details,
                created_at: new Date(),
                created_by: this.authService.user.email,
                completed: false,
                referral_id: referral_id,
            };
            const task_id = this.afs.createId();
            const referralRef = this.afs.collection('referrals').doc(referral_id);
            const taskRef = referralRef.collection('tasks').doc(task_id);
            await taskRef.set({
                ...task,
                id: task_id,
            });
            this.toastsService.showSnackBar('New task created', 'success', 5000);
        } catch (e) {
            console.warn(e);
            this.toastsService.showSnackBar(e, 'error', 5000);
            throw new Error('Error creating new task');
        }
    }

    async onChangeTaskCompletion(task_id, completed, referral_id) {
        console.log(task_id, completed, referral_id);
        try {
            const taskDocRef = this.afs.collection<any>('referrals')
                .doc(referral_id)
                .collection('tasks')
                .doc(task_id);
            taskDocRef.update({
                'completed': completed,
                'completed_at': completed ? new Date() : null,
                'completed_by_email': completed ? this.authService.user.email : null,
                'completed_by': completed ? this.authService.user.displayName : null,
            }).then(() => {
                // using await doesn't work here and causes rendering issues for the toast, so using .then and .catch instead
                this.toastsService.showSnackBar('Task saved', 'success');
            }).catch(e => {
                throw new Error(e);
            });
        } catch (e) {
            this.toastsService.showSnackBar(e, 'error', 5000);
        }
    }

    getStatusDefinition(referral_type, status_type, value) {
        return this.lead_definitions[referral_type]['statuses'][status_type].statuses.find(option => option.value === value);
    }
}
