import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {ReferralsService} from '../../services/referrals.service';

@Component({
    selector: 'app-statuses-badges',
    templateUrl: './statuses-badges.component.html',
    styleUrls: ['./statuses-badges.scss']
})
export class StatusesBadgesComponent implements OnInit, OnChanges {

    @Input() referral_status;
    @Input() insurance_status;
    @Input() insurance_subscriber_id;
    @Input() insurance;
    @Input() referral_type;

    referral_status_label = null;
    insurance_status_label = null;

    constructor(private referralsService: ReferralsService) { }

    calculateStatuses() {
        this.referral_status_label = this.getLabelFromStatusAndValue('referral_status', this.referral_status);
        this.insurance_status_label = this.getLabelFromStatusAndValue('insurance_status', this.insurance_status);
    }

    getLabelFromStatusAndValue(status_name, value) {
        const stages = this.referral_type === 'php-iop' ? this.referralsService.phpIopReferralStages : this.referralsService.tmsReferralStages;
        // look in array of phpIopReferralStages to find the item with the matching status_name
        const found_stage = stages.find(stage => stage.name === status_name);
        // look in the status to find the label with the matching value
        const found_label = found_stage.statuses.find(label => label.value === value);
        return found_label?.label || 'Unknown';
    }

    ngOnInit() {
        this.calculateStatuses();
    }

    ngOnChanges() {
        this.calculateStatuses();
    }
}
