import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ProjectsService} from '../../services/projects.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {GlobalService} from '../../services/global.service';
import {AuthService} from '../../auth/auth.service';
import {GroupsService} from '../../services/groups.service';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import { saveAs } from 'file-saver';
import {environment} from '../../../environments/environment.prod';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: 'unsaved-changes-dialog.html',
    styleUrls: ['unsaved-changes-dialog.scss']
})
export class UnsavedChangesDialogComponent implements OnInit {

    form: FormGroup;
    details_schema;
    details_values;
    form_label = 'Additional Details';

    constructor(
        private afs: AngularFirestore,
        private toastsService: ToastsService,
        private authService: AuthService,
        public projectsService: ProjectsService,
        public globalService: GlobalService,
        private http: HttpClient,
        private groupsService: GroupsService,
        public dialogRef: MatDialogRef<UnsavedChangesDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _formBuilder: FormBuilder) {
        // console.log(this.data);
        // this.form_label = this.data.form_label;
    }

    ngOnInit() {
        // build form using this.details_schema
    }

    onNoClick(): void {
        this.dialogRef.close(false);
    }

    closeDialog() {
        this.dialogRef.close(true);
    }

}
