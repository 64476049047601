import {GlobalService} from '../../services/global.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {ToastsService} from '../../services/toasts.service';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import * as moment from 'moment';
import {HttpClient} from '@angular/common/http';
import {MatTableDataSource} from '@angular/material/table';
import {ActivatedRoute, NavigationEnd, NavigationStart, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort, MatSortable, MatSortHeader} from '@angular/material/sort';
import {GroupsService} from '../../services/groups.service';
import {forkJoin, Observable, Subscription} from 'rxjs';
import {AuthService} from '../../auth/auth.service';
import {ReferralsService} from '../../services/referrals.service';
import {map, startWith} from 'rxjs/operators';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AutoCompleteValidator} from '../../autocomplete-validator';

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.scss']
})
export class TasksComponent implements OnInit, AfterViewInit, OnDestroy {

    tasks = [];
    loading = true;
    tasksColumns: string[] = [
        'status',
        'due_date',
        // 'created_at',
        'type',
        'notes',
        'assigned_to',
        'referral_full_name',

    ];
    tasksDataSource = new MatTableDataSource(null);
    tasksLoading = true;
    membershipsLoaded = false;
    viewAssigned = false;
    includeCompleted = false;
    subscription: Subscription;
    form: FormGroup;
    filteredUsers: Observable<any[]>;
    selectedType = null;

    @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    constructor(private router: Router,
                public globalService: GlobalService,
                public groupsService: GroupsService,
                public referralsService: ReferralsService,
                private http: HttpClient,
                private route: ActivatedRoute,
                private afs: AngularFirestore,
                private toastsService: ToastsService,
                private _formBuilder: FormBuilder,
                public authService: AuthService) {
    }

    async ngOnInit() {
        // this.membershipsLoadedSubscription = this.groupsService.membershipsLoaded$.subscribe(loaded => {
        //     this.membershipsLoaded = loaded;
        //     if (this.membershipsLoaded) {
        //         this.handleRouteAuthentication();
        //     }
        // });
        this.form = this._formBuilder.group({
            assigned_to: ['', [AutoCompleteValidator.mustBeInSimpleList(this.groupsService.allUsers)]],
        });
        this.filteredUsers = this.form.get('assigned_to').valueChanges
            .pipe(
                startWith(''),
                map(value => this.groupsService.filterUsers(value))
            );

        this.form.get('assigned_to').valueChanges.subscribe(() => {
            console.log(this.form.get('assigned_to').value);
            // if assigned_to is in this.groupsService.allUsers then update view. allUsers is an array of objects, with an email field that we will compare
            if (this.form.get('assigned_to').value === '' || this.groupsService.allUsers.find(user => user.email === this.form.get('assigned_to').value)) {
                this.updateView();
            }

        });
        this.tasksDataSource.data = [];
        this.tasks = [];
        await this.loadTasks();
    }

    isOverdue(task) {
        return moment(task.due_date.toDate()).startOf('day').isBefore(moment());
    }

    handleRouteAuthentication() {
        // all users allowed access to referrals page
        return;
    }

    ngAfterViewInit() {
        this.tasksDataSource.paginator = this.paginator;
    }

    async loadTasks() {
        this.tasksLoading = true;
        // TODO only load what's being filtered
        const tasksCollection = this.afs.collectionGroup<any>('tasks', ref => ref.where('referral_id', '>', ''));
        this.subscription = tasksCollection.valueChanges().subscribe(async (tasks) => {
            // sort tasks by due date
            // TODO remove this after we update firebase to v9 so we can use multiple where clauses
            tasks = tasks.sort((a, b) => {
                return a['due_date'].toDate() - b['due_date'].toDate();
            });

            const tasks$ = [];
            for (const task of tasks) {
                // we need to get parents and then promise.all
                const parentDoc = this.afs.doc(`referrals/${task.referral_id}`).get();
                tasks$.push(parentDoc);

            }
            console.log('loading');
            forkJoin(...tasks$).subscribe((parents) => {
                for (let i = 0; i < parents.length; i++) {
                    const parent = parents[i].data();
                    tasks[i].referral = {
                        type: parent.type,
                        id: parent.id,
                        first_name: parent.first_name,
                        last_name: parent.last_name,
                    };
                }
                // this.sort.sort(({id: 'due_date', start: 'desc'}) as MatSortable);
                this.tasks = tasks;
                this.tasksDataSource.data = tasks;
                this.tasksDataSource.sort = this.sort;
                // (this.sort.sortables.get('due_date') as MatSortHeader)._setAnimationTransitionState({ toState: 'active' });
                this.updateView();
                this.tasksLoading = false;
            });
        }, error => {
            this.tasksLoading = false;
            this.toastsService.showSnackBar(error, 'error', 5000);
        });
    }

    toggleViewAssigned() {
        this.viewAssigned = !this.viewAssigned;
        this.updateView();
    }

    toggleIncludeCompleted() {
        this.includeCompleted = !this.includeCompleted;
        this.updateView();
    }

    selectType(type: string) {
        this.selectedType = type;
        this.updateView();
    }

    applyFilter(filterValue: string) {
        this.tasksDataSource.filter = filterValue.trim().toLowerCase();
    }

    updateView() {
        let filteredTasks = this.tasks;

        if (this.selectedType) {
            filteredTasks = filteredTasks.filter(p => p['referral']['type'] === this.selectedType);
        }

        if (this.viewAssigned) {
            filteredTasks = filteredTasks.filter(p => p['assigned_to'] === this.authService.user.email);
        }

        if (this.form.get('assigned_to').value) {
            console.log(this.form.get('assigned_to').value);
            filteredTasks = filteredTasks.filter(p => p['assigned_to'] === this.form.get('assigned_to').value);
        }

        if (!this.includeCompleted) {
            filteredTasks = filteredTasks.filter(p => p['completed'] !== true);
        }

        this.tasksDataSource.data = filteredTasks;
    }

    ngOnDestroy() {
        // this.membershipsLoadedSubscription.unsubscribe();
        this.subscription.unsubscribe();
    }

}
